import Fee from "../../assets/images/fee.svg";
import Duration from "../../assets/images/duration.svg";
import Badge from "../../assets/images/badge.svg";
const SessionDetail = ()=>{
    return(
        <div className="SessionsDetails">
        <div className="FeesStructure">
          <h1>₹799</h1>
          <div className="FeeImageStructure">
            <img src={Fee} />
            <p>Fees</p>
          </div>
        </div>
        <div className="FeesStructure">
          <h1>40 mins</h1>
          <div className="FeeImageStructure">
            <img src={Duration} />
            <p>Session Duration</p>
          </div>
        </div>
        <div className="FeesStructure">
          <h1>4.9/5</h1>
          <div className="FeeImageStructure">
            <img src={Badge} />
            <p>Star Rating</p>
          </div>
        </div>
      </div>
    )
}

export default SessionDetail